.react-add-to-calendar {
  display: flex;
  flex-direction: column;
}

.calendar-button {
  border: 1px solid #123b51;
  border-radius: 4px;
  font-family: 'CustomFour';
  padding: 5px 10px;
  cursor: pointer;
  color: #123b51;
  width: 180px;
  text-align: center;
  margin: 10px 0 0;
}

.react-add-to-calendar__dropdown {
  padding: 0 4px;
  width: 180px;
  position: relative;
}

.react-add-to-calendar__dropdown > ul {
  list-style-type: none;
  padding-left: 10px;
  margin: 0;
  padding-top: 5px;
  border: 1px solid #123b51;
  animation-name: slidein;
  animation-duration: 0.25s;
  position: relative;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes slidein {
  0% {
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.react-add-to-calendar__dropdown > ul > li {
  margin: 10px 0;
}

.react-add-to-calendar__dropdown > ul > li > a {
  text-decoration: none;
  color: #123b51;
}

.react-add-to-calendar__dropdown > ul > li > a:hover {
  color: #bdbdbd;
}
