.fade-in-enter {
  opacity: 0.1;
}

.fade-in-enter-active {
  opacity: 1;
  transition: all 1s ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0.1;
  transition: all 1s ease-in;
}
