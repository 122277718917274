.question {
	color: #5c6071;
	font-family: "CustomFourBold";
	letter-spacing: "1.37px";
}
.link {
	color: #ff6e61;
}
.answer {
	color: #b04b59;
	font-family: "CustomFour";
	letter-spacing: 1.23px;
	text-underline-position: under;
	margin: 1rem 0 !important;
}
.whyCook {
	display: flex;
	flex-direction: column;
}
.answerContainer {
	display: flex;
	flex-direction: column;
}

.unorderedList > li {
	list-style-position: outside;
}

.bold {
	font-weight: bold;
}
