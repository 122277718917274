.sectionTitle {
  color: #1f4050;
  font-family: 'CustomFourBold';
  font-size: 20px;
  letter-spacing: 1.44px;
  line-height: 24px;
  text-align: center;
  margin: '10px 0';
}
.imgContainer {
  height: 130px;
  width: 180px;
}
.imgContainerAlt {
  height: 150px;
  width: 125px;
}
.stepLabelContainer {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  opacity: 0.31;
  background-color: #3f9b69;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepLabel {
  color: #fff;
  font-family: 'CustomFourBold';
  font-size: 50px;
  margin: 0;
}
