.str-chat-channel-list-burger {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .str-chat-channel-list-burger {
    display: none !important;
  }
  .str-chat {
    position: unset !important;
    left: 0 !important;
  }
}
.str-chat {
  height: 100%;
  width: 100% !important;
}

.str-chat * {
  font-family: "CustomFour", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.str-chat-channel-list.div .str-chat__loading_channels {
  width: 100% !important;
}

/* need this for desktop, list padding */
.str-chat__channel-list-messenger__main {
  padding: 0px !important;
}

.messaging.str-chat {
  padding: 0px 0px 0 !important;
}
.str-chat.messaging {
  background-color: white !important;
}

.str-chat__main-panel {
  width: 100%;
  padding: 0 !important;
}

.str-chat__list {
  padding: 0px 4px !important;
}

.str-chat__reverse-infinite-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}

.emoji-mart {
  width: 320px !important;
}

.str-chat__input-footer--count {
  display: none;
}

.str-chat__message-inner {
  margin: 0 !important;
}
.str-chat__message-text-inner {
  max-width: 218px !important;
}

/* For chat footer */
.str-chat__small-message-input__wrapper {
  padding-bottom: env(safe-area-inset-bottom);
}
/* .str-chat__small-message-input__wrapper:focus-within {
  padding-bottom: 0;
} */

.str-chat-channel-list.messaging {
  min-height: unset;
}

/* .str-chat.str-chat-channel-list.messaging.light {
  padding-bottom: calc(68px + env(safe-area-inset-bottom)) !important;
} */

.str-chat.messaging .str-chat__load-more-button__button {
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
}

div[data-testid="thread-action"] {
  display: none !important;
}

.raf-reaction-icon {
  margin: 0;
}
.raf-user-bar__username {
  font-size: 14px;
}

.appear-enter {
  opacity: 0.1;
}

.appear-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.appear-exit {
  opacity: 1;
}

.appear-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.react-add-to-calendar {
  display: flex;
  flex-direction: column;
}

.calendar-button {
  border: 1px solid #123b51;
  border-radius: 4px;
  font-family: 'CustomFour';
  padding: 5px 10px;
  cursor: pointer;
  color: #123b51;
  width: 180px;
  text-align: center;
  margin: 10px 0 0;
}

.react-add-to-calendar__dropdown {
  padding: 0 4px;
  width: 180px;
  position: relative;
}

.react-add-to-calendar__dropdown > ul {
  list-style-type: none;
  padding-left: 10px;
  margin: 0;
  padding-top: 5px;
  border: 1px solid #123b51;
  animation-name: slidein;
  animation-duration: 0.25s;
  position: relative;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes slidein {
  0% {
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.react-add-to-calendar__dropdown > ul > li {
  margin: 10px 0;
}

.react-add-to-calendar__dropdown > ul > li > a {
  text-decoration: none;
  color: #123b51;
}

.react-add-to-calendar__dropdown > ul > li > a:hover {
  color: #bdbdbd;
}

.sectionTitle {
  color: #1f4050;
  font-family: 'CustomFourBold';
  font-size: 20px;
  letter-spacing: 1.44px;
  line-height: 24px;
  text-align: center;
  margin: '10px 0';
}
.imgContainer {
  height: 130px;
  width: 180px;
}
.imgContainerAlt {
  height: 150px;
  width: 125px;
}
.stepLabelContainer {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  opacity: 0.31;
  background-color: #3f9b69;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepLabel {
  color: #fff;
  font-family: 'CustomFourBold';
  font-size: 50px;
  margin: 0;
}

.scale-in-enter {
  opacity: 0.1;
  transform: translate(50px, -50px);
}

.scale-in-enter-active {
  transform: translate(0px, 0px);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.scale-in-exit {
  transform: translate(0px, 0px);
  opacity: 1;
}

.scale-in-exit-active {
  transform: translate(50px, -50px);
  transition: all 300ms ease-in-out;
}

.carousel-steps-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
}

.carousel-arrow-left {
  border-radius: 5px 0 0 5px;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 1px;
  color: white;
}

.carousel-arrow-left-disabled {
  cursor: not-allowed;
  border-radius: 5px 0 0 5px;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 1px;
  color: white;
}

.carousel-arrow-right {
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 0px;
  color: white;
}

.carousel-arrow-right-disabled {
  cursor: not-allowed;
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 0px;
  color: white;
}

.hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

@keyframes bob {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.loading-dot-1 {
  animation: bob 1s ease-in-out infinite;
}

.loading-dot-2 {
  animation: bob 1s ease-in-out infinite;
  animation-delay: 100ms;
}

.loading-dot-3 {
  animation: bob 1s ease-in-out infinite;
  animation-delay: 200ms;
}

/* ROTATE  */

.rotate-enter {
  transform: rotate(180deg);
}

.rotate-enter-active {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}

.rotate-enter-done {
  transform: rotate(0deg);
}

.rotate-exit {
  transform: rotate(0deg);
}

.rotate-exit-active {
  transform: rotate(180deg);
  transition: transform 200ms linear;
}

.rotate-exit-done {
  transform: rotate(180deg);
}

@keyframes appear {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.fade-in-enter {
  opacity: 0.1;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-in-enter {
  opacity: 0.1;
}

.fade-in-enter-active {
  opacity: 1;
  transition: all 1s ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0.1;
  transition: all 1s ease-in;
}

@keyframes delayed-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
}

.delayed-animation {
  animation-delay: 500ms;
  animation: delayed-appear 300ms ease-in;
}

.fade-in-enter {
  opacity: 0.1;
  transform: translateY(100px);
}

.fade-in-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0.1;
  transform: translateY(100px);
  transition: all 300ms ease-out;
}

/* used for diner ad dialog */
@keyframes slide-in {
  from {
    transform: translateY(-10%);
    opacity: 0.1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.question {
	color: #5c6071;
	font-family: "CustomFourBold";
	letter-spacing: "1.37px";
}
.link {
	color: #ff6e61;
}
.answer {
	color: #b04b59;
	font-family: "CustomFour";
	letter-spacing: 1.23px;
	text-underline-position: under;
	margin: 1rem 0 !important;
}
.whyCook {
	display: flex;
	flex-direction: column;
}
.answerContainer {
	display: flex;
	flex-direction: column;
}

.unorderedList > li {
	list-style-position: outside;
}

.bold {
	font-weight: bold;
}

.tada-enter {
  animation: 0.8s tada ease;
}

.pulse-enter {
  animation: 1s pulse ease;
}

.bounce-enter {
  animation: 0.6s bounce ease;
}

.slide-up-enter {
  transform: translateY(75%);
  opacity: 0.1;
}

.slide-up-enter-active {
  transform: translateY(0%);
  opacity: 1;
  transition: all 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55); /* easeInOutBack */
}

.slide-up-enter-done {
  transform: translateY(0%);
  opacity: 1;
}

.slide-up-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-up-exit-active {
  transform: translateY(75%);
  opacity: 0;
  transition: all 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55); /* easeInOutBack */
}

.slide-up-exit-done {
  display: none;
  transform: translateY(75%);
  opacity: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*****************************************/

/* new font-family for body tag, was added during transition to cook alliance,
 see definition for sizing */
/* @font-face {
	font-family: "CustomThree";
	src: url("./fonts/playfair_display/static/PlayfairDisplay-Regular.ttf")
		format("tff");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
} */

@font-face {
	font-family: "CustomFour";
	src: url(/static/media/proxima_soft.69ea5fb2.woff2) format("woff2"),
		url(/static/media/proxima_soft.d2a686a2.woff) format("woff");
	/*src: url("./fonts/opensans/opensans.ttf") format("ttf");*/

	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFourMedium";
	src: url(/static/media/proxima_soft_medium.7244172e.woff2) format("woff2"),
		url(/static/media/proxima_soft_medium.a9211da3.woff) format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFourBold";
	src: url(/static/media/proxima_soft_bold.2f7bfd5f.woff2) format("woff2"),
		url(/static/media/proxima_soft_bold.519ad000.woff) format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFive";
	src: url(/static/media/proxima_condensed.83739be5.woff2) format("woff2"),
		url(/static/media/proxima_condensed.5a146ab3.woff) format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSix";
	src: url(/static/media/Abril_Text_Regular.23a4c778.otf) format("opentype");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSixBold";
	src: url(/static/media/Abril_Text_Bold.ea43b605.otf) format("opentype");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSeven";
	src: url(/static/media/proxima_soft_extra_bold.6cc6b35b.woff2) format("woff2"),
		url(/static/media/proxima_soft_extra_bold.6391d46d.woff) format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
}

ol,
ul {
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 2.5rem;
}

html {
	/* Do NOT put any more style here! Additional styles should go on the body tag. If you need
  to put styles here because of cordova, add them in index.tsx */

	/* This defines what 1 rem is */
	font-size: 62.5%; /* 1rem = 10px; changed from 62.5% to 12px/16px = 75%. Older: 10px/16px = 62.5% */
}
/* Added new font-family CustomThree set to Display Fair. 
This is the default font for the entire site. It is set to 1.6rem (16px) by default. */
body {
	font-family: "CustomFour", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	box-sizing: border-box;
}

/* Adds padding to the bottom of the page once the footer has 
has been enables so content won't go below it*/
/* @media only screen and (max-width: 62em) {
  body {
    padding-bottom: calc(5rem + env(safe-area-inset-bottom));
  }
} */

/* Typography. Line height is 120% of the font size */

button {
	font-family: "CustomFourBold";
}
/* 
h1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: "CustomFourBold";
}

h2 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-family: "CustomFourBold";
}

h3 {
  font-size: 1.17rem;
  line-height: 1.404rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.2rem;
}

h5 {
  font-size: 0.83rem;
  line-height: 0.996rem;
}

h6 {
  font-size: 0.67rem;
  line-height: 0.804rem;
} */

/* p,
div,
ol,
ul {
  font-size: 1rem;
  line-height: 1.2rem;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.table-header-cell {
	text-align: center !important;
}

.rc-pagination {
	text-align: center !important;
}

.paypal-button-text {
	display: none !important;
}

.hidden-label {
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	        clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

/* Animation Purposes  */

.appear-enter {
	opacity: 0.1;
}

.appear-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.appear-exit {
	opacity: 1;
}

.appear-exit-active {
	opacity: 0.1;
	transition: opacity 300ms ease-in;
}

/* For Native Date Picker */

input[type="date"] {
	position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
	content: "\25BC";
	color: #555;
	padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
	color: #28bd4d;
}

@media (max-width: 700px) {
	input[type="date"]:hover:after {
		display: none;
	}
	input[type="date"]:after {
		display: none;
	}
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	color: transparent;
	background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
	z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
	z-index: 1;
}

/* App's footer - deprecated */
/* #bottom-nav-container button {
  padding-bottom: calc(6px + env(safe-area-inset-bottom));
} */

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
	padding: 0px 5px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}
.hamburger:hover {
	opacity: 0.7;
}
.hamburger.is-active:hover {
	opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #000;
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 30px;
	height: 3px;
	background-color: #000;
	border-radius: 3px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: -10px;
}
.hamburger-inner::after {
	bottom: -10px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
	top: -20px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(-90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
	top: -20px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.grecaptcha-badge {
	visibility: hidden;
}

