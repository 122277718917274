/* ROTATE  */

.rotate-enter {
  transform: rotate(180deg);
}

.rotate-enter-active {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}

.rotate-enter-done {
  transform: rotate(0deg);
}

.rotate-exit {
  transform: rotate(0deg);
}

.rotate-exit-active {
  transform: rotate(180deg);
  transition: transform 200ms linear;
}

.rotate-exit-done {
  transform: rotate(180deg);
}
