.scale-in-enter {
  opacity: 0.1;
  transform: translate(50px, -50px);
}

.scale-in-enter-active {
  transform: translate(0px, 0px);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.scale-in-exit {
  transform: translate(0px, 0px);
  opacity: 1;
}

.scale-in-exit-active {
  transform: translate(50px, -50px);
  transition: all 300ms ease-in-out;
}
