.carousel-steps-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
}

.carousel-arrow-left {
  border-radius: 5px 0 0 5px;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 1px;
  color: white;
}

.carousel-arrow-left-disabled {
  cursor: not-allowed;
  border-radius: 5px 0 0 5px;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 1px;
  color: white;
}

.carousel-arrow-right {
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 0px;
  color: white;
}

.carousel-arrow-right-disabled {
  cursor: not-allowed;
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-color: white;
  border-width: 1px 1px 1px 0px;
  color: white;
}

.hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}
