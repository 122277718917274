/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*****************************************/

/* new font-family for body tag, was added during transition to cook alliance,
 see definition for sizing */
/* @font-face {
	font-family: "CustomThree";
	src: url("./fonts/playfair_display/static/PlayfairDisplay-Regular.ttf")
		format("tff");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
} */

@font-face {
	font-family: "CustomFour";
	src: url("./fonts/proxima_soft.woff2") format("woff2"),
		url("./fonts/proxima_soft.woff") format("woff");
	/*src: url("./fonts/opensans/opensans.ttf") format("ttf");*/

	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFourMedium";
	src: url("./fonts/proxima_soft_medium.woff2") format("woff2"),
		url("./fonts/proxima_soft_medium.woff") format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFourBold";
	src: url("./fonts/proxima_soft_bold.woff2") format("woff2"),
		url("./fonts/proxima_soft_bold.woff") format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomFive";
	src: url("./fonts/proxima_condensed.woff2") format("woff2"),
		url("./fonts/proxima_condensed.woff") format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSix";
	src: url("./fonts/Abril_Text_Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSixBold";
	src: url("./fonts/Abril_Text_Bold.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

@font-face {
	font-family: "CustomSeven";
	src: url("./fonts/proxima_soft_extra_bold.woff2") format("woff2"),
		url("./fonts/proxima_soft_extra_bold.woff") format("woff");
	/* src: url("./fonts/opensans/opensans.ttf") format("ttf"); */
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	speak: none;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
}

ol,
ul {
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 2.5rem;
}

html {
	/* Do NOT put any more style here! Additional styles should go on the body tag. If you need
  to put styles here because of cordova, add them in index.tsx */

	/* This defines what 1 rem is */
	font-size: 62.5%; /* 1rem = 10px; changed from 62.5% to 12px/16px = 75%. Older: 10px/16px = 62.5% */
}
/* Added new font-family CustomThree set to Display Fair. 
This is the default font for the entire site. It is set to 1.6rem (16px) by default. */
body {
	font-family: "CustomFour", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	box-sizing: border-box;
}

/* Adds padding to the bottom of the page once the footer has 
has been enables so content won't go below it*/
/* @media only screen and (max-width: 62em) {
  body {
    padding-bottom: calc(5rem + env(safe-area-inset-bottom));
  }
} */

/* Typography. Line height is 120% of the font size */

button {
	font-family: "CustomFourBold";
}
/* 
h1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: "CustomFourBold";
}

h2 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-family: "CustomFourBold";
}

h3 {
  font-size: 1.17rem;
  line-height: 1.404rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.2rem;
}

h5 {
  font-size: 0.83rem;
  line-height: 0.996rem;
}

h6 {
  font-size: 0.67rem;
  line-height: 0.804rem;
} */

/* p,
div,
ol,
ul {
  font-size: 1rem;
  line-height: 1.2rem;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.table-header-cell {
	text-align: center !important;
}

.rc-pagination {
	text-align: center !important;
}

.paypal-button-text {
	display: none !important;
}

.hidden-label {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

/* Animation Purposes  */

.appear-enter {
	opacity: 0.1;
}

.appear-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.appear-exit {
	opacity: 1;
}

.appear-exit-active {
	opacity: 0.1;
	transition: opacity 300ms ease-in;
}

/* For Native Date Picker */

input[type="date"] {
	position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
	content: "\25BC";
	color: #555;
	padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
	color: #28bd4d;
}

@media (max-width: 700px) {
	input[type="date"]:hover:after {
		display: none;
	}
	input[type="date"]:after {
		display: none;
	}
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	color: transparent;
	background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
	z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
	z-index: 1;
}

/* App's footer - deprecated */
/* #bottom-nav-container button {
  padding-bottom: calc(6px + env(safe-area-inset-bottom));
} */

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
	padding: 0px 5px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}
.hamburger:hover {
	opacity: 0.7;
}
.hamburger.is-active:hover {
	opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #000;
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 30px;
	height: 3px;
	background-color: #000;
	border-radius: 3px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: -10px;
}
.hamburger-inner::after {
	bottom: -10px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
	top: -20px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(-90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
	top: -20px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.grecaptcha-badge {
	visibility: hidden;
}
