.fade-in-enter {
  opacity: 0.1;
  transform: translateY(100px);
}

.fade-in-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0.1;
  transform: translateY(100px);
  transition: all 300ms ease-out;
}

/* used for diner ad dialog */
@keyframes slide-in {
  from {
    transform: translateY(-10%);
    opacity: 0.1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
