@keyframes bob {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.loading-dot-1 {
  animation: bob 1s ease-in-out infinite;
}

.loading-dot-2 {
  animation: bob 1s ease-in-out infinite;
  animation-delay: 100ms;
}

.loading-dot-3 {
  animation: bob 1s ease-in-out infinite;
  animation-delay: 200ms;
}
