.str-chat-channel-list-burger {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .str-chat-channel-list-burger {
    display: none !important;
  }
  .str-chat {
    position: unset !important;
    left: 0 !important;
  }
}
.str-chat {
  height: 100%;
  width: 100% !important;
}

.str-chat * {
  font-family: "CustomFour", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.str-chat-channel-list.div .str-chat__loading_channels {
  width: 100% !important;
}

/* need this for desktop, list padding */
.str-chat__channel-list-messenger__main {
  padding: 0px !important;
}

.messaging.str-chat {
  padding: 0px 0px 0 !important;
}
.str-chat.messaging {
  background-color: white !important;
}

.str-chat__main-panel {
  width: 100%;
  padding: 0 !important;
}

.str-chat__list {
  padding: 0px 4px !important;
}

.str-chat__reverse-infinite-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}

.emoji-mart {
  width: 320px !important;
}

.str-chat__input-footer--count {
  display: none;
}

.str-chat__message-inner {
  margin: 0 !important;
}
.str-chat__message-text-inner {
  max-width: 218px !important;
}

/* For chat footer */
.str-chat__small-message-input__wrapper {
  padding-bottom: env(safe-area-inset-bottom);
}
/* .str-chat__small-message-input__wrapper:focus-within {
  padding-bottom: 0;
} */

.str-chat-channel-list.messaging {
  min-height: unset;
}

/* .str-chat.str-chat-channel-list.messaging.light {
  padding-bottom: calc(68px + env(safe-area-inset-bottom)) !important;
} */

.str-chat.messaging .str-chat__load-more-button__button {
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
}

div[data-testid="thread-action"] {
  display: none !important;
}
