@import "~animate.css/animate.css";

.tada-enter {
  animation: 0.8s tada ease;
}

.pulse-enter {
  animation: 1s pulse ease;
}

.bounce-enter {
  animation: 0.6s bounce ease;
}

.slide-up-enter {
  transform: translateY(75%);
  opacity: 0.1;
}

.slide-up-enter-active {
  transform: translateY(0%);
  opacity: 1;
  transition: all 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55); /* easeInOutBack */
}

.slide-up-enter-done {
  transform: translateY(0%);
  opacity: 1;
}

.slide-up-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-up-exit-active {
  transform: translateY(75%);
  opacity: 0;
  transition: all 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55); /* easeInOutBack */
}

.slide-up-exit-done {
  display: none;
  transform: translateY(75%);
  opacity: 0;
}
