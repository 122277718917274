@keyframes appear {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}
