@keyframes delayed-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
}

.delayed-animation {
  animation-delay: 500ms;
  animation: delayed-appear 300ms ease-in;
}
