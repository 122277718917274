.appear-enter {
  opacity: 0.1;
}

.appear-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.appear-exit {
  opacity: 1;
}

.appear-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
